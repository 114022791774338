














































































import { Component, Vue } from 'vue-property-decorator';
import SsoForm from './sso-form.vue';
import { getSsoList, deleteSso } from '@/api/system/SsoApi';

@Component({
  name: 'SsoList',
  components: { SsoForm }
})
export default class SsoList extends Vue {
  formSearchModel: any = { name: '', clientType: '' };
  loadingOuter: boolean = false;
  formListData: any = [];
  // 当前数据
  currtent: any = null;
  // 是否显示新增编辑弹框
  isShowAddEdit: boolean = false;

  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;

  // 加载数据
  mounted() {
    this.getFormPage();
  }

  /**
   * 获取表格数据
   */
  getFormPage(): void {
    let params = {
      name: this.formSearchModel.name,
      clientType: this.formSearchModel.clientType,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.loadingOuter = true;
    getSsoList(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.loadingOuter = false;
        this.formListData = res.data.list;
        this.total = res.data.total;
      }
    });
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.pageIndex = 1;
    this.getFormPage();
  }

  /**
   * 重置
   */
  handleSearchReset() {
    this.pageIndex = 1;
    this.formSearchModel = { name: '', clientType: '' };
    this.getFormPage();
  }

  /**
   * 添加
   */
  addForm(row) {
    this.currtent = row;
    this.isShowAddEdit = true;
  }

  /**
   * 删除
   */
  remove(row?: any) {
    deleteSso(row.id).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getFormPage();
      }
    });
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getFormPage();
  }

  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getFormPage();
  }
}
