/**
 * 单点实体
 */
export interface SsoModel {
  id?: string;
  name: string;
  ssoUrl?: string;
  clientType: string;
  privateKey: string;
  tokenExp: number;
  refreshTokenExp: number;
  loginOutUrl: string;
  customerSystemFlag: number;
  resolverTokenUrl: string;
  resolverTokenClassName: string;
}

export const SsoData: SsoModel = {
  id: '',
  name: '',
  ssoUrl: '',
  clientType: '',
  privateKey: '',
  tokenExp: null,
  refreshTokenExp: null,
  loginOutUrl: '',
  customerSystemFlag: null,
  resolverTokenUrl: '',
  resolverTokenClassName: ''
};
