

















































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { addOrEditBizAuthClient } from '@/api/system/SsoApi';
import { SsoModel, SsoData } from '@/models/system/SsoModel';
@Component({
  name: 'SsoForm'
})
export default class SsoForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 当前数据
  @Prop({ default: null }) currtent!: SsoModel;
  // 表单加载状态
  formLoading: boolean = false;
  // 表单数据
  ssoData: SsoModel = SsoData;
  // 表单验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_client_name'),
      trigger: 'blur'
    },
    ssoUrl: {
      required: true,
      message: this.$t('lang_please_entry_address_sso'),
      trigger: 'blur'
    },
    clientType: {
      required: true,
      message: this.$t('lang_please_select_client_type'),
      trigger: 'change'
    },
    customerSystemFlag: {
      required: true,
      message: this.$t('lang_please_select_whether_the_customer_system'),
      trigger: 'change'
    },
    privateKey: {
      required: true,
      message: this.$t('lang_please_entry_private_token'),
      trigger: 'blur'
    },
    tokenExp: {
      required: true,
      message: this.$t('lang_please_enter_the_token_expiration_time'),
      trigger: 'blur'
    },
    refreshTokenExp: {
      required: true,
      message: this.$t('lang_please_entry_refresh_token_expiration_time'),
      trigger: 'blur'
    },
    loginOutUrl: {
      required: true,
      message: this.$t('lang_please_entry_exit_login_url'),
      trigger: 'blur'
    },
    // resolverTokenUrl: {
    //   required: true,
    //   message: this.$t('lang_please_entry_client_system_token_url'),
    //   trigger: 'blur'
    // },
    // resolverTokenClassName: {
    //   required: true,
    //   message: this.$t('lang__client_system_parses_token_class_name'),
    //   trigger: 'blur'
    // }
  };

  created() {
    if (this.currtent) {
      this.ssoData = this.currtent;
    } else {
      this.ssoData = SsoData;
    }
  }

  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      addOrEditBizAuthClient(this.ssoData)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.ssoData = SsoData;
            this.$emit('success', res.data);
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {});
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
