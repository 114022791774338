import HttpUtil from '@/utils/HttpUtil';

// 获取单点列表
export const getSsoList = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/bizAuth/page';
  return HttpUtil.post(url, params);
};

// 新增编辑单点
export const addOrEditBizAuthClient = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/bizAuth/addOrEditBizAuthClient';
  return HttpUtil.post(url, params);
};

// 删除单点
export const deleteSso = (authClientId: string) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/bizAuth/delete';
  return HttpUtil.get(url, { authClientId });
};
