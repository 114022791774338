var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.currtent
          ? _vm.$t("lang_edit_single_point")
          : _vm.$t("lang_add_single_point"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.ssoData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_client_name"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.ssoData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssoData, "name", $$v)
                          },
                          expression: "ssoData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "overLong",
                      attrs: {
                        label: _vm.$t("lang_address_sso"),
                        prop: "ssoUrl",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.ssoData.ssoUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssoData, "ssoUrl", $$v)
                          },
                          expression: "ssoData.ssoUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_client_type"),
                            prop: "clientType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_client_type"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ssoData.clientType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ssoData, "clientType", $$v)
                                },
                                expression: "ssoData.clientType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_web_browser"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_app_mobile_phone"),
                                  value: 2,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_whether_the_customer_system"),
                            prop: "customerSystemFlag",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_whether_the_customer_system"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.ssoData.customerSystemFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ssoData,
                                    "customerSystemFlag",
                                    $$v
                                  )
                                },
                                expression: "ssoData.customerSystemFlag",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_yes"),
                                  value: true,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_no"),
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "overLong",
                      attrs: {
                        label: _vm.$t("lang_private_token"),
                        prop: "privateKey",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.ssoData.privateKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssoData, "privateKey", $$v)
                          },
                          expression: "ssoData.privateKey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_token_expiration_time"),
                            prop: "tokenExp",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0 },
                            model: {
                              value: _vm.ssoData.tokenExp,
                              callback: function ($$v) {
                                _vm.$set(_vm.ssoData, "tokenExp", $$v)
                              },
                              expression: "ssoData.tokenExp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_refresh_token_expiration_time"),
                            prop: "refreshTokenExp",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0 },
                            model: {
                              value: _vm.ssoData.refreshTokenExp,
                              callback: function ($$v) {
                                _vm.$set(_vm.ssoData, "refreshTokenExp", $$v)
                              },
                              expression: "ssoData.refreshTokenExp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_exit_login_url"),
                        prop: "loginOutUrl",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.ssoData.loginOutUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssoData, "loginOutUrl", $$v)
                          },
                          expression: "ssoData.loginOutUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "overLong",
                      attrs: {
                        label: _vm.$t("lang_client_system_token_url"),
                        prop: "resolverTokenUrl",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.ssoData.resolverTokenUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssoData, "resolverTokenUrl", $$v)
                          },
                          expression: "ssoData.resolverTokenUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "overLong",
                      attrs: {
                        label: _vm.$t(
                          "lang__client_system_parses_token_class_name"
                        ),
                        prop: "resolverTokenClassName",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.ssoData.resolverTokenClassName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ssoData, "resolverTokenClassName", $$v)
                          },
                          expression: "ssoData.resolverTokenClassName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }