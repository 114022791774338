var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "div",
        { staticClass: "page-search-bar" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.formSearchModel,
                inline: "",
                "label-suffix": ": ",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "mgb-0",
                  attrs: { label: _vm.$t("lang_client_name") },
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$t("lang_please_entry_client_name"),
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSearch($event)
                      },
                    },
                    model: {
                      value: _vm.formSearchModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formSearchModel, "name", $$v)
                      },
                      expression: "formSearchModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "mgb-0",
                  attrs: { label: _vm.$t("lang_client_type") },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("lang_please_select_client_type"),
                        clearable: "",
                      },
                      model: {
                        value: _vm.formSearchModel.clientType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formSearchModel, "clientType", $$v)
                        },
                        expression: "formSearchModel.clientType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("lang_web_browser"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("lang_app_mobile_phone"),
                          value: 2,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "mgb-0" },
                [
                  _c("el-button", {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.handleSearch },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-refresh",
                    },
                    on: { click: _vm.handleSearchReset },
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-plus",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addForm()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingOuter,
                  expression: "loadingOuter",
                },
              ],
              staticClass: "page-pagetable",
              attrs: { border: "", data: _vm.formListData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_client_name"),
                  align: "center",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_client_type"),
                  align: "center",
                  prop: "clientType",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.clientType == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(_vm.$t("lang_web_browser"))),
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(_vm._s(_vm.$t("lang_app_mobile_phone"))),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_token_expiration_time") + "(s)",
                  align: "center",
                  prop: "tokenExp",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_refresh_token_expiration_time") + "(s)",
                  align: "center",
                  prop: "refreshTokenExp",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_whether_the_customer_system"),
                  align: "center",
                  prop: "customerSystemFlag",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.customerSystemFlag
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v(_vm._s(_vm.$t("lang_yes"))),
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(_vm._s(_vm.$t("lang_no"))),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("lang_operation"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#007aff" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.addForm(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              "cancel-button-text": _vm.$t("lang_cancel_"),
                              "confirm-button-text": _vm.$t("lang_determine_"),
                              title: _vm.$t(
                                "lang_are_you_sure_to_delete_this_single_point"
                              ),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.remove(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#e03e2d",
                                  "margin-left": "10px",
                                },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "page-pager",
            attrs: {
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.changePageSize,
              "current-change": _vm.changePage,
            },
          }),
        ],
        1
      ),
      _vm.isShowAddEdit
        ? _c("sso-form", {
            attrs: { visible: _vm.isShowAddEdit, currtent: _vm.currtent },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              success: _vm.getFormPage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }